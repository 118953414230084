import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bjorn/git/entur-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "products"
    }}>{`Products`}</h1>
    <p>{`Welcome to the Products API.`}</p>
    <p>{`The products API provides endpoints for generating offers, fetching, creating and changing datamanaged objects.`}</p>
    <h2 {...{
      "id": "headers"
    }}>{`Headers`}</h2>
    <h3 {...{
      "id": "authentication"
    }}>{`Authentication`}</h3>
    <p>{`The endpoints concerning datamanaged objects is protected by Auth0, which means that you'll need an API Key to access them.
To get an access token from Auth0 you can do as follows:`}</p>
    <pre><code parentName="pre" {...{}}>{`curl --request POST \\
  --url 'https://partner.staging.entur.org/oauth/token' \\
  --header 'content-type: application/json' \\
  --data '{"grant_type":"client_credentials","client_id": "<clientid>","client_secret": "<clientsecret>","audience": "https://api.staging.entur.io"}
`}</code></pre>
    <p>{`In the output, locate the access_token value.`}</p>
    <p>{`If you wish to extract the access token directly into a shell variable, do this:`}</p>
    <pre><code parentName="pre" {...{}}>{`export TOKEN=$(curl --request POST \\
  --url 'https://partner.staging.entur.org/oauth/token' \\
  --header 'content-type: application/json' \\
  --data '{"grant_type":"client_credentials","client_id": "<clientid>","client_secret": "<clientsecret>","audience": "https://api.staging.entur.io"}\\
  | jq .access_token | sed -e 's/"//g')
`}</code></pre>
    <h3 {...{
      "id": "language-code"
    }}>{`Language code`}</h3>
    <p>{`Most datamanaged objects have a name and description in several languages. It is possible to get it in a certain language by adding "Accept-Language" key in the header with a code for the language. It is not possible to specify multiple languages. However, if none is specified, name and description will appear in all available languages.`}</p>
    <h3 {...{
      "id": "example"
    }}>{`Example`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Header key`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Example value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Language code`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Accept-Language`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`nob`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Authorization`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Authorization`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Bearer xxxxxxx.yyyyyyyy.zzzzzzzzz`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      